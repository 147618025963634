import React from 'react';
import { Typography } from '@material-ui/core';
import Layout, { Wrapper } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { Branding } from '../components/Branding';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SuccessMessage } from '../components/Alerts';

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Preferences Confirmed"
        siteUrl="https://bloggingfordevs.com"
        description="Preferences Confirmed"
        pathname="preferences-confirmed/"
        noIndex
      />
      <ProductHuntBanner />
      <div
        style={{
          maxWidth: '500px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Wrapper>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Preferences Confirmed
          </Typography>
          <SuccessMessage>
            Thanks for letting us know about your contact preferences, they've
            been updated successfully.
          </SuccessMessage>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
